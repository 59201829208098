<template>
  <section class="main-section">
     <div class="filters">
       <label>Filter by</label>
       <ul>
         <li v-if="me.is_admin">
           <user-groups-filter v-model="filters.user_groups" :label="filter_label('groups')" />
         </li>
         <li>
           <user-segment-filter v-model="filters.user_segments" :label="filter_label('segments')" />
         </li>
       </ul>
     </div>

     <div class="content">
       <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table v-if="activity.length > 0" :rows="activity" :columns="['Date', 'User', 'Reward type', 'Redemption status', 'Reward name', 'Points spent', 'Currency value']" :sortable="{'1': 'created_at', '2': 'user.name', '3': 'type', '4': 'status', '6': 'points', '7': currency_value}">
            <template #rows="{rows, sort_by}">
              <tr v-for="(row, index) in rows" :key="`activity_row_${sort_by}_${index}`">
                <td>
                  {{$formatDate(row.created_at)}}
                </td>
                <td>
                  <user-card :user="row.user" :height="22" />
                </td>
                <td>
                  {{row.type}}
                </td>
                <td>
                  {{row.status}}
                </td>
                <td>
                  {{redemption_name(row)}}
                </td>
                <td>
                  {{$formatPoints(row.points)}}
                </td>
                <td>
                  {{currency_value(row)}}
                </td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>No activity found with these filters.</div>
        </template>
        <loading-indicator v-else />
       </div>
     </div>
  </section>
</template>

<script>
import {debounce} from 'lodash'

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups'
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments'

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    UserSegmentFilter
  },
  computed: {
    me() {
      return this.$store.state.user;
    }
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity()
      }
    }
  },
  data() {
    return {
      activity: null,
      filters: {
        user_groups: [],
        user_segments: [],
        date_range: this.dateRange
      }
    }
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    this.populateActivity();

    this.$parent.$on('exportTable', this.export)
  },
  methods: {
    populateActivity: debounce( async function() {
      const resp = await this.$api.Reporting.get_redemptions(this.filters)
      this.activity = resp;
    }, 250 ),
    filter_label(filter) {
      switch(filter) {
        case "segments":
          return this.filters.user_segments.length > 0 ? `Segments (${this.filters.user_segments.length})` : 'Segments';
        case "groups":
          return this.filters.user_groups.length > 0 ? `Channels (${this.filters.user_groups.length})` : 'Channels';
      }
    },
    redemption_name({redemption, type}) {
      switch(type) {
        case "CUSTOM":
          return redemption.name || '';
        case "CUSTOM_CATALOG":
          return redemption.name || '';
        case "INTEGRATION_CUSTOM":
          return redemption.name || '';
        case "GIFT_CARD":
          return redemption.rewardName;
        case "AMAZON":
          return redemption.title;
        case "PAYPAL":
          return `PayPal/Venmo Cash out`;
        case "DONATION":
          return `${redemption.name} (${this.$formatEIN(redemption.ein)})`;
      }

      return '';
    },
    product_variant({redemption, type}) {
      if(type === 'CUSTOM_CATALOG' || type === 'CUSTOM') {
        return redemption.variant ? redemption.variant.name : 'N/A';
      }

      if(type === 'INTEGRATION_CUSTOM') {
        return redemption.variant_name || 'N/A';
      }

      return 'N/A';
    },
    shipping_address({redemption, type}, section) {
      if(!('ship_to' in redemption)) return 'N/A';

      if(type === 'CUSTOM_CATALOG' || type === 'CUSTOM') {
        switch(section) {
          case 'NAME':
            return `${redemption.ship_to.name}`;
          case 'STREET':
            return `${redemption.ship_to.street1 + (redemption.ship_to.street2 ? ', ' + redemption.ship_to.street2 : '')}`;
          case 'CITY':
            return `${redemption.ship_to.city}`;
          case 'STATE':
            return `${redemption.ship_to.state}`;
          case 'POSTAL':
            return `${redemption.ship_to.postal}`;
          case 'COUNTRY':
            return `${redemption.ship_to.country}`;
        }
      }
      else if( type === 'INTEGRATION_CUSTOM') {
        switch(section) {
          case 'NAME':
            return `${redemption.ship_to.name}`;
          case 'STREET':
            return `${redemption.ship_to.address + (redemption.ship_to.address1 ? '\n' + redemption.ship_to.address1 : '')}`;
          case 'CITY':
            return `${redemption.ship_to.city}`;
          case 'STATE':
            return `${redemption.ship_to.state}`;
          case 'POSTAL':
            return `${redemption.ship_to.zip}`;
          case 'COUNTRY':
            return `${redemption.ship_to.country}`;
        }
      }

      return 'N/A';
    },
    currency_value({redemption, type}) {
      switch(type) {
        case "PAYPAL":
          return this.$formatCurrency(redemption.sent_amount, redemption.currency, true);
        case "GIFT_CARD":
          return this.$formatCurrency(redemption.currencyAmount, redemption.currencyCode, true);
        case "CUSTOM":
          return 'N/A';
        case "CUSTOM_CATALOG":
          return 'N/A';
        case "DONATION":
          return this.$formatCurrency(redemption.points/100, 'USD', true);
        case "AMAZON":
          return this.$formatCurrency((redemption.price + redemption.tax), redemption.currency, true);
      }

      return 'N/A';
    },
    platform_fee({points, redemption, type}) {
      switch(type) {
        case "PAYPAL":
          return this.$formatCurrency((points * 0.1)/100, 'USD', true);
        case "GIFT_CARD":
          return this.$formatCurrency((points * 0.1)/100, 'USD', true);
        case "CUSTOM":
          return 'N/A';
        case "CUSTOM_CATALOG":
          return 'N/A';
        case "DONATION":
          return this.$formatCurrency((points * 0.1)/100, 'USD', true);
        case "AMAZON":
          return this.$formatCurrency((points * 0.1)/100, 'USD', true);
      }

      return 'N/A';
    },
    amount_billed({points, redemption, type}) {
      switch(type) {
        case "PAYPAL":
          return this.$formatCurrency((points + (points * 0.1))/100, 'USD', true);
        case "GIFT_CARD":
          return this.$formatCurrency((points + (points * 0.1))/100, 'USD', true);
        case "CUSTOM":
          return 'N/A';
        case "CUSTOM_CATALOG":
          return 'N/A';
        case "DONATION":
          return this.$formatCurrency((points + (points * 0.1))/100, 'USD', true);
        case "AMAZON":
          return this.$formatCurrency((points + (points * 0.1))/100, 'USD', true);
      }

      return 'N/A';
    },
    reward_value({points, redemption, type}) {
      switch(type) {
        case "PAYPAL":
          return this.$formatCurrency((points)/100, 'USD', true);
        case "GIFT_CARD":
          return this.$formatCurrency((points)/100, 'USD', true);
        case "CUSTOM":
          return 'N/A';
        case "CUSTOM_CATALOG":
          return 'N/A';
        case "DONATION":
          return this.$formatCurrency((points)/100, 'USD', true);
        case "AMAZON":
          return this.$formatCurrency((points)/100, 'USD', true);
      }

      return 'N/A';
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce((agg, [key, val]) => {
        if(key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
        return agg;
      }, [])

      window.generateCSV('HiThrive Redemption Report.csv', [
        ['Date', 'User Email', 'User Name', 'Reward Type', 'Redemption Status', 'Reward Name', 'Points Spent', 'Currency Value', 'Reward Value (USD)', 'Amount Billed (USD)', 'Platform Fee (USD)', 'Post-pay Transaction ID', 'Product Variant', 'Shipping Address (Name)', 'Shipping Address (Street)', 'Shipping Address (City)', 'Shipping Address (State)', 'Shipping Address (Postal)', 'Shipping Address (Country)', ...custom_fields],
        ..._.map(this.activity, r => ([
        this.$csvDateFormat(r.created_at),
          r.user.email,
          r.user.name,
          r.type,
          r.status,
          this.redemption_name(r),
          r.points,
          this.currency_value(r),
          this.reward_value(r),
          this.amount_billed(r),
          this.platform_fee(r),
          r.post_pay_payment_id || 'N/A',
          this.product_variant(r),
          this.shipping_address(r, 'NAME'),
          this.shipping_address(r, 'STREET'),
          this.shipping_address(r, 'CITY'),
          this.shipping_address(r, 'STATE'),
          this.shipping_address(r, 'POSTAL'),
          this.shipping_address(r, 'COUNTRY'),
          ...Object.entries(r).reduce((agg, [k, v]) => {
            if(k.substr(0, 3) === 'CF_') agg.push(v);
            return agg;
          }, [])
        ]))
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 25px 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  ::v-deep > table {
    margin: 0;
    font-size: 14px;
    
    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>